import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./Yes-Wordmark-Cream.svg";
import pattern from "./pattern.png";

function MainPage() {
  const navigate = useNavigate();

  const [formClicked, setFormClicked] = useState(false);
  const location = useLocation();

  const navigateToForm = () => {
    // 👇️ navigate to /form
    navigate("/form");
    setFormClicked(true);
  };

  return (
    <section class="yes-page">
      <div className="App">
        <div className="overlay"></div>
        <header
          className="App-header vh-100"
          style={{
            backgroundImage: `url(${pattern})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {location.pathname !== "/form" && !formClicked && (
            <div class="App-login">
              <img
                data-aos="fade-up"
                src={logo}
                className="Touch-logo mb-lg-5"
                alt="logo"
              />
              <button
                onClick={navigateToForm}
                className="btn text-uppercase btn-primary cursor-pointer my-5"
              >
                {`touch me, please`}&nbsp;{`>`}
              </button>
            </div>
          )}
        </header>
      </div>
    </section>
  );
}

export default MainPage;
