import React from "react";
import { WaiverStyled } from "./Waiver.styled";

function Waiver() {
  return (
    <WaiverStyled>
      <h2 class="color-please">Yes! Club Membership Waiver and Rules</h2>
      <div className="scroll-page">
        <h3>Membership Agreement</h3>
        <p>
          By becoming a member of Yes! Club, you are agreeing to abide by the
          rules and guidelines outlined in this waiver. Failing to comply with
          these guidelines may result in the retraction of your membership
          and/or removal from the premises. Yes! Club reserves the right to deny
          membership/entry to any person(s) at its sole discretion.
        </p>

        <h3>Membership Requirements</h3>
        <p>
          Membership at Yes Club! Is only available to person(s) who are at
          least 19 years of age.
        </p>
        <p>
          A valid government-issued photo ID will be required for membership and
          entry.
        </p>
        <p>
          Membership/entry fees must be paid in full and are non-refundable. In
          the event of ticketed nights, proof of purchase will be required for
          entry and/or purchase of a ticket at the door.
        </p>
        <p>Entry fees are non-refundable unless approved by management.</p>

        <h3>Code of Conduct and Behavior</h3>
        <p>
          All members will be required to watch the educational consent video as
          well as read and sign this waiver before entering the club. You can
          save time by watching the video and signing the waiver in advance
          through our website, allowing you quicker entry once through the line.
        </p>
        <p>
          <strong>Consent and Respect:</strong> Understanding that consent is a
          mutual agreement that is enthusiastic, ongoing, freely given, and can
          be withdrawn at any time. No unwanted touching or watching is
          permitted in this space.
        </p>
        <p>
          In alignment with our consent guidelines, respect and human dignity
          are core values at Yes! Club; this means treating all staff and other
          members with respect and care. Any unwanted behavior, discrimination,
          or inappropriate conduct will lead to a removal from the club and, in
          some cases, a personal ban.
        </p>
        <p>
          Consent stewards are there to keep party-goers safe and will be
          available to you should you ever feel unsafe or uncomfortable or
          simply looking for some guidance or best practices on how to engage in
          the space! Consent stewards will be easily identifiable to guests.
        </p>
        <p>
          Rules for engagement and consent policies will also be posted
          in/around play areas for reference.
        </p>

        <h3>Safe Sex Practices</h3>
        <p>
          Condoms and other safer sex supplies will be provided and encouraged
          for all sexual activities.
        </p>
        <p>
          We encourage open communication about safer sex preferences and
          practices between all person(s) engaging in sexual activities.
        </p>

        <h3>Dress Code</h3>
        <p>
          Dress to express - think of the dress code as being an expression of
          oneself or a proclamation of the kind of person you want to be that
          night. We encourage guests to dress to match the energy of the space.
          No effort, no entry!
        </p>

        <h3>Consumption Policy</h3>
        <p>We strictly enforce the legal drinking age.</p>
        <p>
          We reserve the right to limit access to areas of the club should our
          staff deem that guests have overconsumed.
        </p>

        <h3>Play Room Guidelines</h3>
        <p>
          You must be accompanied by a partner or a group to enter the play
          area.
        </p>
        <p>No single persons will be allowed entry into the play area.</p>
        <p>
          All personal belongings must be placed in lockers before heading into
          the play area.
        </p>

        <h3>Personal Belongings</h3>
        <p>
          We are not responsible for lost or stolen items. You may keep your
          personal belongings secure in our lockers. Guests will create their
          own 4-digit numeric passcode that will allow them access to their
          personal lockers (similar to that of a lockbox code). Don't worry, if
          you forget your code, visit the desk in the locker area, and a staff
          member will be able to open your locker for you (staff will ask to
          verify the contents of the locker to ensure it is yours).
        </p>

        <h3>Photography and Recording</h3>
        <p>
          No photography or recording of any kind is allowed unless prior
          permission is received from management. Stickers will be given to all
          members upon entry to cover phone cameras. The removal of your sticker
          can result in an automatic ban from the club - if you need a new
          sticker, please find a staff member immediately to provide you with a
          replacement.
        </p>

        <h3>Membership Termination</h3>
        <p>
          Membership may be terminated by the club at any time for violation of
          these rules.
        </p>

        <h3>Waiver of Liability</h3>
        <p>
          By entering Yes! Club, you acknowledge that you are participating
          voluntarily and at your own risk. Members acknowledge that sexual
          activity and nudity occur on club premises. Furthermore, each member
          acknowledges and takes responsibility for all sexual activities that
          they may engage in on club premises. Members acknowledge that these
          activities must be consensual in nature, not given under coercion or
          assumption, and not taken under the condition of financial
          compensation.
        </p>
        <p>
          Yes! Club is a non-smoking venue; if you wish to smoke, there is a
          back entrance to a designated smoking area for guests.
        </p>
      </div>
    </WaiverStyled>
  );
}

export default Waiver;
