import styled from "styled-components";

export const WaiverStyled = styled.div`
  p {
    font-size: 18px;
  }

  h2 {
    padding: 30px 0;
  }

  .scroll-page {
    height: calc(50vh - 100px);
    overflow: auto;
    border: 1px solid #e9546c;
    padding: 30px;
    margin-bottom: 10px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #141414;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #141414;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e9546c;
    }
  }
`;
