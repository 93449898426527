import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./Yes-Wordmark-Cream.svg";
import pattern from "./pattern.png";

const LoginPage = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const appPassword = process.env.REACT_APP_PASSWORD;

  useEffect(() => {
    const storedPassword = localStorage.getItem("passwordClubYes");
    if (storedPassword) {
      setPassword(storedPassword);
    }
  }, []);

  const handleLogin = () => {
    console.log(appPassword, password);
    if (password === appPassword) {
      localStorage.removeItem("password");
      navigate("/main");
    } else {
      alert("Invalid password. Please try again.");
    }
  };

  return (
    <section class="yes-page">
      <div className="App">
        <div className="overlay"></div>
        <header
          className="App-header vh-100"
          style={{
            backgroundImage: `url(${pattern})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div class="App-login">
            <img
              src={logo}
              data-aos="fade-up"
              className="Touch-logo mb-lg-5"
              alt="logo"
            />
            <div className="form-group my-5 mb-0 text-center">
              <input
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div
              className="my-4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="btn text-uppercase btn-primary cursor-pointer my-5"
                onClick={handleLogin}
              >
                Enter {`>`}
              </button>
            </div>
          </div>
        </header>
      </div>
    </section>
  );
};

export default LoginPage;
