import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormStyle } from "./Form.styled";
import { useNavigate } from "react-router-dom";
import rabbit from "../../Yes-Icon-Cream.svg";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const [isAnimatingBunny, setIsAnimatingBunny] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      instagram: "",
    },
  });
  const onSubmit = (data) => {
    navigate("/submit", { state: { formData: data } });
    handleImageClick();
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleImageClick = () => {
    setIsAnimatingBunny(true);
  };

  const animationClass = isAnimatingBunny ? "animate-bunny" : "";

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
      console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    }

    return isValid;
  };

  useEffect(() => {}, [isAnimatingBunny]);

  return (
    <FormStyle>
      <div className="container">
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className="input-style">
            <label className="input-label" htmlFor="firstname">
              First name :
            </label>
            <div className="input-wrapper">
              <input
                {...register("firstname", {
                  required: true,
                })}
                type="text"
                id="firstname"
                name="firstname"
                placeholder=""
              />
            </div>
          </div>
          {errors.firstname?.type === "required" && (
            <p className="color-please small-text alert-text" role="alert">
              First name is required
            </p>
          )}
          <div className="input-style">
            <label className="input-label" htmlFor="lastname">
              Last name :
            </label>
            <div className="input-wrapper">
              <input
                {...register("lastname", {
                  required: true,
                })}
                type="text"
                id="lastname"
                name="lastname"
                placeholder=""
              />
            </div>
          </div>
          {errors.lastname?.type === "required" && (
            <p className="color-please small-text alert-text" role="alert">
              Last name is required
            </p>
          )}
          <div className="input-style">
            <label className="input-style" htmlFor="name">
              Email :
            </label>
            <div className="input-wrapper">
              <input
                {...register("email", {
                  required: true,
                  validate: handleEmailValidation,
                })}
                type="email"
                name="email"
                placeholder=""
              />
            </div>
          </div>
          {errors.email?.type === "required" && (
            <p className="color-please small-text ps-0 alert-text" role="alert">
              Email is required
            </p>
          )}
          {errors.email?.type === "validate" && (
            <p className="color-please small-text ps-0 alert-text" role="alert">
              The email is not correct
            </p>
          )}
          <div className="input-style">
            <label htmlFor="name">Instagram :</label>
            <div className="pseudo-input">
              <span className="prefix">@</span>
              <input
                {...register("instagram", { required: false })}
                type="text"
                id="instagram"
                name="instagram"
                placeholder=""
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <button className="btn btn-primary text-uppercase cursor-pointer my-5">
              {loading ? "Loading..." : "Submit >"}
            </button>
            <img
              src={rabbit}
              alt="bunny"
              class={`rabbit-image ${animationClass}`}
            />
          </div>
        </form>
      </div>
    </FormStyle>
  );
};

export default Form;
