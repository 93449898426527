import styled from "styled-components";

export const FormStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: transparent;
    margin-top: 0;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 610px) {
      padding: 20px;
    }

    .input-style {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;

      @media (max-width: 610px) {
        flex-direction: column;
        align-items: flex-start;
      }

      label {
        font-family: "DM Mono", monospace;
        display: block;
        min-width: 300px;
        text-align: left;
        color: #e8556d;
        font-size: 36px;

        @media (max-width: 1024px) {
          font-size: 28px; /* Font size for iPad and iPad Air */
          min-width: 240px;
          text-overflow: nowrap;
        }

        @media (max-width: 610px) {
          font-size: 24px; /* Font size for iPad and iPad Air */
          min-width: auto;
        }
      }

      input {
        outline: none;
        border: none;
        background-color: transparent;
        border: 2px solid #06869f;
        border-radius: 0;
        color: #06869f;
        max-height: 62px;
        margin: 14px 0;
      }
      input[type="submit"] {
        background-color: #52154e;
        color: #ffffffff;
        font-weight: bold;
      }
    }
  }
`;
