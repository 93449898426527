import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "./components/Form/Form";

function FormPage() {
  // triggering function
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  return (
    <section class="yes-page">
      <div className="container">
        <div className="row min-vh-100">
          <div className="col-lg-12 min-vh-100 d-flex align-items-center justify-content-center">
            <Form />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormPage;
