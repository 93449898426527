import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "./Yes-Wordmark-Cream.svg";
import FormPage from "./FormPage";
import pattern from "./pattern.png";

function PolicyPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // State to store the timeout ID
  const [navigateToMainTimer, setNavigateToMainTimer] = useState(null);

  useEffect(() => {
    // Set the timeout and store its ID in state
    const timerId = setTimeout(() => {
      navigate("/main");
    }, 3000);
    setNavigateToMainTimer(timerId);

    // Clean up the timer when the component unmounts
    return () => {
      if (navigateToMainTimer) {
        clearTimeout(navigateToMainTimer);
      }
    };
  }, []);

  return (
    <section className="yes-page">
      <div className="App">
        <div className="overlay"></div>
        <header
          className="App-header vh-100"
          style={{
            backgroundImage: `url(${pattern})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div class="App-login">
            <img
              src={logo}
              data-aos="fade-down"
              className="Touch-logo mb-lg-5"
              alt="logo"
            />
            {location.pathname !== "/form" && (
              <button className="btn text-uppercase btn-primary my-5 dont-touch">
                Thank You
              </button>
            )}
          </div>
        </header>
      </div>
    </section>
  );
}

export default PolicyPage;
