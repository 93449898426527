// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  HashRouter,
  useLocation,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import MainPage from "./MainPage";
import FormPage from "./FormPage";
import PolicyPage from "./PolicyPage";
import ThanksPage from "./ThanksPage";
import { motion } from "framer-motion";
// import logo from './logo.svg';
import "./App.css";

function App() {
  const PageLayout = ({ children }) => children;

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.5,
  };

  const AnimationLayout = () => {
    const { pathname } = useLocation();
    return (
      <PageLayout>
        <motion.div
          key={pathname}
          initial="initial"
          animate="in"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Outlet />
        </motion.div>
      </PageLayout>
    );
  };

  return (
    <div className="App">
      <Routes>
        <Route element={<AnimationLayout />}>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/main" element={<MainPage />}></Route>
          <Route path="/form" element={<FormPage />}></Route>
          <Route path="/submit" element={<PolicyPage />}></Route>
          <Route path="/thanks" element={<ThanksPage />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
