import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Waiver from "./components/Waiver/Waiver";
import rabbit from "./Yes-Icon-Cream.svg";
import axios from "axios";

function PolicyPage() {
  const navigate = useNavigate();

  const location = useLocation();

  const [isAnimatingBunny, setIsAnimatingBunny] = useState(false);

  const formData = location.state?.formData;

  const handleImageClick = () => {
    setIsAnimatingBunny(true);
    console.log("isAnimatingBunny:", isAnimatingBunny);
  };

  const components = {
    h1: (props) => <h1 style={{ color: "blue" }} {...props} />,
    p: (props) => <p style={{ fontSize: "18px" }} {...props} />,
  };

  const animationClass = isAnimatingBunny ? "animate-bunny" : "";

  const prodApi = process.env.REACT_APP_API_URL;

  const handleSubmit = (data) => {
    data.instagram = `https://instagram.com/${data.instagram}`;
    axios
      .post(prodApi, data)
      .then((response) => {
        console.log(response);
        setTimeout(() => {
          navigate("/thanks");
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {}, [isAnimatingBunny]);

  const submitForm = () => {
    handleImageClick();
    handleSubmit(formData);
    setTimeout(() => {
      navigate("/thanks");
    }, 1500);
  };

  return (
    <section class="yes-page">
      <div className="App">
        <header className="App-header">
          {location.pathname !== "/form" && (
            <div class="container">
              <div className="row">
                <div className="col-12">
                  <div class="text-uppercase color-please" data-aos="fade-down">
                    <Waiver />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <button
                      onClick={submitForm}
                      className="btn text-uppercase btn-primary cursor-pointer my-5"
                    >
                      Agree {`>`}
                    </button>
                    <img
                      src={rabbit}
                      alt="bunny"
                      class={`rabbit-image rabbit-image-agree ${animationClass}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </header>
      </div>
    </section>
  );
}

export default PolicyPage;
